import gsap from 'gsap';
import marquee from './marquee';

let masterTl;
let logoTl;
let restTl;

function throttleAnimation(animation, fps) {
  let frame = 0;

  const ticker = gsap.ticker;
  const time = ticker.time;
  const frameLength = 1 / fps;

  function update() {
    const newTime = ticker.time - time;
    const newFrame = ~~(newTime / frameLength);
    if (frame !== newFrame) {
      frame = newFrame;
      animation.time(newTime);
      if (animation.progress() === 1) {
        ticker.remove(update);
      }
    }
  }

  animation.pause()
  ticker.add(update)
}

function positionArrow() {
  const heroEl = document.querySelector('#homeHero');
  const heroSVG = document.querySelector('#heroSVG');
  const arrow = heroSVG.querySelector('#arrow');
  const world = heroSVG.querySelector('#world');
  const homeTop = document.querySelector('#homeTop');

  // Visible area
  const hero = heroEl.getBoundingClientRect();
  // Scaled SVG
  const svg = heroSVG.getBoundingClientRect();
  // SVG Viewbox
  const viewbox = heroSVG.viewBox.baseVal;
  // Get original bounding boxes for arrow and world
  const arrowBB = arrow.getBBox();
  const worldBB = world.getBBox();

  // Accomodate for home top overlap
  const homeTopOffset = parseInt(window.getComputedStyle(homeTop).marginTop);

  // Relation of hero's height to scaled SVG's height
  // We need to map the hero baseline into the SVG viewbox
  const hX = (hero.height + homeTopOffset) / svg.height;

  // Use this to map the hero baseline to original viewbox co-ordinates
  const mappedHeroBaseline = viewbox.height * hX;

  // Minus the arrow's height to get the Y position
  const adjustedY = mappedHeroBaseline - arrowBB.height;

  // Minus new Y position from current to get the offset
  const yOffset = arrowBB.y - adjustedY;

  // Set circle radius
  // We can't really do this programatically as the circle is not regular
  // So this is based on a 'best fit' radius for the wobbly circle
  // Worked out in Illustrator
  const radius = 614;

  // Get the centre point of the circle, to calculate some right-angled
  // triangles so we can employ Pythagoras theorem.
  const worldCentreY = worldBB.y + radius;
  const worldCentreX = worldBB.x + radius;

  // Work out length of Y in our right-angled triangle
  // The final magic number is compensating for the fact we want the arrow's
  // tip to be touching the circle
  const yLength = worldCentreY - mappedHeroBaseline + (arrowBB.height - 106.85);

  // Use Pythagoras to work out length of X
  const xLength = Math.sqrt((radius * radius) - (yLength * yLength));

  // Find new X position
  const adjustedX = (worldCentreX - xLength) - arrowBB.width;

  // Minus new X position from current to get offset
  const xOffset = (arrowBB.x - adjustedX);

  arrow.setAttribute('transform', `translate(${-xOffset},${-yOffset})`);
}

function initAnim() {
  const heroEl = document.querySelector('#homeHero');
  const svgMain = document.querySelector('#heroSVG');
  const svgLogo = document.querySelector('#heroLogo');
  const earth = svgMain.querySelector('#world');
  const nice = svgLogo.querySelector('#nice');
  const and = svgLogo.querySelector('#and');
  const serious = svgLogo.querySelector('#serious');
  const creative = svgMain.querySelector('#tagline-creative');
  const work = svgMain.querySelector('#tagline-work');
  const the = svgMain.querySelector('#tagline-the');
  const world = svgMain.querySelector('#tagline-world');
  const needs = svgMain.querySelector('#tagline-needs');

  gsap.ticker.fps(30);

  masterTl = gsap.timeline({
    onStart: () => {
      heroEl.classList.remove('home-hero-is-loading');
    },
    onComplete: () => {
      gsap.ticker.fps(60);
      marquee.play();
    }
  });
  logoTl = gsap.timeline();
  restTl = gsap.timeline();

  logoTl.set([nice, and, serious], { yPercent: -2500 });
  restTl.set([creative, work, the, world, needs], {
    yPercent: -2500,
    xPercent: 10,
  });

  masterTl.add(logoTl).add(restTl, '-=0.2');

  // Logo
  logoTl.from(serious, {
    duration: 0.5,
    yPercent: -2500,
    opacity: 0,
    ease: 'power2.in',
  })
  .set(masterTl, {timeScale: 0.4})
  .to(serious, {
    duration: 0.05,
    yPercent: -25,
    yoyo: true,
    repeat: 1,
    transformOrigin: '50% 100%',
    ease: 'power2.out',
  })
  .from(and, {
    duration: 0.5,
    yPercent: -2500,
    rotate: 45,
    ease: 'power2.in',
    transformOrigin: '50% 50%',
  }, '>-0.5')
  .to(and, {
    duration: 0.05,
    rotate: -5,
    yPercent: -10,
    transformOrigin: '50% 100%',
  })
  .to(and, {
    duration: 0.05,
    rotate: 0,
    yPercent: 0,
    transformOrigin: '50% 100%',
    ease: 'power2.in',
  })
  .from(nice, {
    duration: 1,
    yPercent: -2500,
    rotate: 45,
    ease: 'power2.in',
    transformOrigin: '50% 50%',
  }, '>-1')
  .to(nice, {
    duration: 0.05,
    rotate: -5,
    yPercent: -10,
    transformOrigin: '50% 100%',
  })
  .to(nice, {
    duration: 0.05,
    rotate: 0,
    yPercent: 0,
    transformOrigin: '50% 100%',
    ease: 'power2.in',
  });

  // Creative work the world needs
  restTl
  .fromTo(
    [the, world, needs],
    { yPercent: -2500, xPercent: 10 },
    { duration: 1, yPercent: -2, ease: 'power2.in' },
  )
  .to([the, world, needs], {
    duration: 0.1,
    stagger: 0.01,
    yPercent: 0,
    xPercent: 0,
    ease: 'power1.in',
  })
  .fromTo(
    [creative, work],
    { yPercent: -2500, xPercent: 10 },
    { duration: 1, yPercent: -1, ease: 'power2.in' },
    '>-1',
  )
  .to([creative, work], {
    duration: 0.1,
    stagger: 0.01,
    yPercent: 0,
    xPercent: 0,
    ease: 'power1.in',
  })
  .from(earth, {
    rotation: "180deg",
    transformOrigin: '50% 50%',
    duration: 0.5,
    xPercent: 100,
    ease: 'power2.in'
  }, '>-0.5')
  .to(earth, {
    duration: 0.1,
    xPercent: 1,
    yoyo: true,
    repeat: 1,
    transformOrigin: '50% 100%',
    ease: 'power2.out',
  })
  .to(needs, {
    duration: 0.1,
    rotate: -2,
    yPercent: -2,
    yoyo: true,
    repeat: 1,
    transformOrigin: '50% 100%',
  }, '>-0.2')
  .from(arrow, {
    duration: 1,
    xPercent: -1000,
    yPercent: -500,
    ease: 'power2.in',
  }, '>-1')
  .to(arrow, {
    duration: 0.05,
    xPercent: -25,
    yPercent: -10,
    yoyo: true,
    repeat: 1,
    transformOrigin: '50% 100%',
    ease: 'power2.out',
  });
}

function handleResize() {
  const home = document.querySelector('#home');
  if (!home) {
    return;
  }
  const tls = [logoTl, restTl];
  const targets = tls.map(tl => {
    const childTweens = tl.getChildren();
    const childTargets = childTweens.map(tween => tween.targets()).flat();
    return childTargets;
  })
  .flat()
  .filter(item => Object.keys(item).length === 1);

  masterTl.kill();
  logoTl.kill();
  restTl.kill();

  gsap.killTweensOf(targets);
  gsap.set(targets, {clearProps: 'all'});
  positionArrow();
}

function initResizeHandler() {
  // Setup a timer
  let timeout;
  // Listen for resize events
  window.addEventListener('resize', () => {
    // If there's a timer, cancel it
    if (timeout) {
      window.cancelAnimationFrame(timeout);
    }
      // Setup the new requestAnimationFrame()
    timeout = window.requestAnimationFrame(() => {
      handleResize();
    })
  }, false);
}

function init() {
  const home = document.querySelector('#home');
  if (!home) {
    return;
  }

  positionArrow();
  initAnim();
  initResizeHandler();
  marquee.init();
}

init();
