import gsap from 'gsap';

const tl = gsap.timeline({repeat: -1, paused: true});

const playTl = () => {
  tl.play();
}

const init = () => {
  const home = document.querySelector('#home');
  if (!home) {
    return;
  }
  // this.destroy();
  const content = document.querySelector('.js-marquee-content');
  const parent = content.parentElement;
  const contentWidth = content.getBoundingClientRect().width;
  const parentWidth = parent.getBoundingClientRect().width;
  const requiredReps = contentWidth > parentWidth ? 2 : Math.ceil((parentWidth - contentWidth) / contentWidth) + 1;
  let els = [content];

  for (let i = 0; i < requiredReps; i++) {
    const clone = content.cloneNode(true);
    clone.classList.add('js-clone');
    parent.appendChild(clone);
    els.push(clone);
  }

  els.forEach(el => {
    tl
    .to(el, {
      duration: 20,
      x: -contentWidth,
      ease: 'none'
    }, 0)
    .set(el, {x: `-=${parent.scrollWidth}`});
  })
}

const marquee = {
  init: init,
  play: playTl,
}

export default marquee;
